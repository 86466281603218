import { ChangeEvent, Fragment, useMemo, useState } from 'react';
import { arrange, EmojiGroup } from 'emoji-api';
import { IconButton, Popover, Tab, Tabs, TextField } from '@material-ui/core';
import { EmojiEmotionsOutlined, Search } from '@material-ui/icons';

import { usePopoverState } from 'shared/hooks';
import { VirtualizedGrid } from 'shared/components/symbols';

import { Container, EmojiContainer, Content } from './IconButtonEmojis.styles';

const GROUPS = arrange();
const GROUP_NAMES = Object.keys(GROUPS) as EmojiGroup[];

export interface IconButtonEmojisProps {
  /**
   * Callback function to handle the selection of an emoji.
   * @param emoji The selected emoji.
   */
  onSelect(emoji: string): void;
}

/**
 * A component that renders a button with emoji icons and a popover containing
 * a searchable list of emojis grouped by categories.
 *
 * @ticket https://github.com/jebelapp/jebel/issues/1535
 */
export function IconButtonEmojis(props: IconButtonEmojisProps) {
  const [selectedGroup, setSelectedGroup] = useState(GROUP_NAMES[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const { show, anchor, open, close } = usePopoverState();

  const emojis = useMemo(() => {
    return GROUPS[selectedGroup].filter(
      emoji => emoji.name.includes(searchQuery) || emoji.fancyName.includes(searchQuery),
    );
  }, [selectedGroup, searchQuery]);

  const handleChangeSelectedGroup = (_event: unknown, selected: EmojiGroup) => {
    setSelectedGroup(selected);
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const wrapEmojiClick = (emoji: string | undefined) => () => {
    if (emoji) {
      props.onSelect(emoji);
    }
  };

  return (
    <Fragment>
      <IconButton title="icons" onClick={open}>
        <EmojiEmotionsOutlined fontSize="inherit" />
      </IconButton>

      <Popover open={show} anchorEl={anchor} onClose={close}>
        <Container>
          <Tabs
            variant="scrollable"
            scrollButtons="on"
            value={selectedGroup}
            onChange={handleChangeSelectedGroup}
          >
            {GROUP_NAMES.map(group => {
              const icon = GROUPS[group][0];

              return <Tab key={group} label={`${icon} ${group}`} value={group} />;
            })}
          </Tabs>

          <Content>
            <TextField
              variant="outlined"
              value={searchQuery}
              onChange={handleChangeSearchQuery}
              placeholder={`Search through "${selectedGroup}" emojis`}
              InputProps={{ endAdornment: <Search color="action" /> }}
              fullWidth
            />

            <VirtualizedGrid data={emojis} rowMinWidth={40} rowHeight={40}>
              {params => {
                const emoji = params.item?.toString();

                return (
                  <EmojiContainer
                    key={params.key}
                    style={params.style}
                    onClick={wrapEmojiClick(emoji)}
                  >
                    {emoji}
                  </EmojiContainer>
                );
              }}
            </VirtualizedGrid>
          </Content>
        </Container>
      </Popover>
    </Fragment>
  );
}
