import { ReactNode, UIEvent, useCallback, useLayoutEffect, useRef } from 'react';
import {
  AutoSizer,
  ColumnSizer,
  Grid,
  GridCellProps,
  OnScrollParams,
  ScrollSync,
} from 'react-virtualized';
import { Container } from './VirtualizedGrid.styles';

export type VirtualizedGridRendererProps<T> = GridCellProps & {
  item: T | undefined;
};

export type VirtualizedGridRenderer<T> = (props: VirtualizedGridRendererProps<T>) => ReactNode;

export interface VirtualizedGridProps<T> {
  rowMinWidth: number;
  rowHeight: number;

  columnCount?: number;

  data: T[];

  children: VirtualizedGridRenderer<T>;
}

export function VirtualizedGrid<T = unknown>(props: VirtualizedGridProps<T>) {
  const container = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (container.current) {
      // Ensure the scroll position is reset when the data changes.
      container.current.scrollTop = 0;
    }
  }, [props.data]);

  function calculateColumnCount(width: number) {
    return Math.floor(width / props.rowMinWidth);
  }

  const wrapScroll = useCallback(
    (onScroll: (params: OnScrollParams) => void) => (event: UIEvent<HTMLDivElement>) => {
      onScroll({
        clientHeight: event.currentTarget.clientHeight,
        clientWidth: event.currentTarget.clientWidth,
        scrollHeight: event.currentTarget.scrollHeight,
        scrollLeft: event.currentTarget.scrollLeft,
        scrollTop: event.currentTarget.scrollTop,
        scrollWidth: event.currentTarget.scrollWidth,
      });
    },
    [],
  );

  return (
    <ScrollSync>
      {({ scrollTop, onScroll }) => (
        <Container ref={container} onScroll={wrapScroll(onScroll)}>
          <AutoSizer>
            {({ width, height }) => {
              const columnCount = props.columnCount ?? calculateColumnCount(width);
              const rowCount = Math.ceil(props.data.length / columnCount);

              return (
                <ColumnSizer columnCount={columnCount} width={width}>
                  {({ adjustedWidth, getColumnWidth, registerChild }) => (
                    <Grid
                      ref={registerChild}
                      autoHeight
                      width={adjustedWidth}
                      height={height}
                      columnCount={columnCount}
                      columnWidth={getColumnWidth}
                      rowCount={rowCount}
                      rowHeight={props.rowHeight}
                      overscanRowCount={3}
                      scrollTop={scrollTop}
                      onScroll={onScroll}
                      cellRenderer={params => {
                        const index = params.rowIndex * columnCount + params.columnIndex;
                        const item = props.data[index] as T;

                        return props.children({ ...params, item });
                      }}
                    />
                  )}
                </ColumnSizer>
              );
            }}
          </AutoSizer>
        </Container>
      )}
    </ScrollSync>
  );
}
