import { Fragment, useCallback, useMemo, useState } from 'react';

import { EventStatuses, EVENT_STATUSES, SORT, US_TIMEZONES } from '@jebel/constants';
import { createFilterBuilder, createInboxSubject } from '@jebel/utils';

import {
  EventFilter,
  EventInfoFragment,
  EventsListQuery,
  EventsListQueryVariables,
} from 'shared/graphql';
import { EventsCreateModal } from 'features/events/components/Events';
import { ChooseStatusChipOption, Icon, Modal } from 'shared/components/ui';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  useSpreadsheetContext,
  Spreadsheet,
  SpreadsheetCellActions,
  SortInfoOption,
} from 'shared/features/spreadsheet';
import { EVENTS_LIST_QUERY } from 'shared/graphql/event';
import { getEventAddress } from 'shared/utils/address';
import { formatTableDate, getFullMonthAndWeekdayAndTime } from 'shared/utils/date';
import {
  useCurrentUser,
  useDownloadLazyQueryCSV,
  useInboxMessageSend,
  useModalState,
  useSchoolConfiguration,
} from 'shared/hooks';
import { formatUserName } from 'shared/utils/user';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import {
  EventsReportHeaders,
  eventsListTableHeadlines,
  eventsSpreadsheetFilters,
} from '../constants';
import { useEventStatusHandle } from '../hooks';
import { ChooseEventStatusChip } from './ChooseEventStatusChip';

const DEFAULT_TIMEZONE = 'CST';

const checkEventStatus = (
  event: EventInfoFragment | null | undefined,
  statusesToCheck: EventStatuses[],
): boolean => {
  if (!event?.status) return false;
  return statusesToCheck.includes(event.status as EventStatuses);
};

const getSortParams = (sortInfo: SortInfoOption | null): SortInfoOption[] => {
  const defaultSort: SortInfoOption = { date: SORT.desc };
  if (!sortInfo) return [defaultSort];
  // override default sort if sortInfo contains date
  if (sortInfo.date) return [sortInfo];
  return [sortInfo, defaultSort];
};

export function EventsList() {
  const [eventCopy, setEventCopy] = useState<EventInfoFragment>();
  const [wasEventEdited, setWasEventEdited] = useState<boolean>(false);

  const {
    show: isEventDuplicateModalOpened,
    open: onModalOpen,
    close: onModalClose,
  } = useModalState();

  const { data: school } = useSchoolConfiguration();
  const { onChangeEventStatus } = useEventStatusHandle();
  const { createMessage } = useInboxMessageSend({ withContext: false });
  const { queryParams, currentRowId, selected } = useSpreadsheetContext<EventsListQueryVariables>();
  const { userId } = useCurrentUser();

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<
    EventsListQuery,
    EventsListQueryVariables
  >({
    skip: !school,
    query: EVENTS_LIST_QUERY,

    queryVariables: {
      ...queryParams,

      filter: {
        ...queryParams.filter,

        school: {
          // Filter events by school.
          // https://github.com/jebelapp/jebel/issues/1678
          id: { equals: school?.id },
        },
      },
    },

    searchingFields: [
      'title',
      'description',
      'author.firstName',
      'author.lastName',
      'author.fullName',
      'titleSponsor.name',
      'location.city',
      'location.state',
      'location.zip',
      'location.street1',
    ],
  });

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<EventFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const count = tableData?.eventsList.count ?? 0;

  const [downloadHandler] = useDownloadLazyQueryCSV<EventsListQuery, EventsListQueryVariables>(
    EVENTS_LIST_QUERY,
    {
      filename: getFileNameWithTimestamp('Events.csv'),
      variables: {
        filter: downloadFilter,
        sort: getSortParams(queryParams.sort as SortInfoOption),
      },

      transform(response) {
        const events = response.eventsList.items ?? [];

        return events.map(
          ({ title, date, location, author, createdAt, titleSponsor, status, timezone }) => ({
            [EventsReportHeaders.name]: title,
            [EventsReportHeaders.date]: getFullMonthAndWeekdayAndTime(
              date,
              US_TIMEZONES.find(item => item.abbreviation === timezone)?.abbreviation ||
                DEFAULT_TIMEZONE,
            ),
            [EventsReportHeaders.location]: getEventAddress(location),
            [EventsReportHeaders.createdBy]: formatUserName(author),
            [EventsReportHeaders.createdOn]: formatTableDate(createdAt),
            [EventsReportHeaders.title]: `${titleSponsor?.name ?? 'No title sponsor found.'}`,
            [EventsReportHeaders.status]: status,
          }),
        );
      },
    },
  );

  const onApproveEventClick = useCallback(
    async (id: string) => {
      await onChangeEventStatus({ status: EVENT_STATUSES.active, id, isApproved: true });
    },
    [onChangeEventStatus],
  );

  const onDuplicateEventClick = useCallback(
    (id: string) => {
      setWasEventEdited(false);
      setEventCopy(tableData?.eventsList?.items?.find(event => event.id === id));
      onModalOpen();
    },
    [onModalOpen, tableData?.eventsList?.items],
  );

  const onViewDetailsClick = useCallback(
    (id: string) => {
      setWasEventEdited(true);
      setEventCopy(tableData?.eventsList?.items.find(event => event.id === id));
      onModalOpen();
    },
    [onModalOpen, tableData?.eventsList?.items],
  );

  const onRejectClick = async (id: string) => {
    const event = tableData?.eventsList?.items?.find(event => id === event.id);

    if (!event || !school) {
      return;
    }

    await onChangeEventStatus({ status: EVENT_STATUSES.inactive, id, isApproved: false });

    if (userId === event?.author?.id) {
      return;
    }

    const subject = createInboxSubject({
      content: 'Your event has been rejected.',
      isFromSchool: true,
      school: {
        id: school.id,
        name: school.name,
      },
    });

    const message = `Your event "${event?.title}" has been rejected. Contact administrator for further instructions.`;

    await createMessage({
      subject,
      text: message,
      userIds: [userId as string, event.author?.id as string],
    });
  };

  const isActionsVisible = useMemo(
    () => ({
      approve: (selectedEvent: EventInfoFragment | undefined) =>
        selected.length
          ? (
              tableData?.eventsList?.items.filter(({ id }) => selected.includes(id ?? '')) as
                | EventInfoFragment[]
                | undefined
            )?.every(admin => checkEventStatus(admin, [EVENT_STATUSES.pendingApproval]))
          : checkEventStatus(selectedEvent, [EVENT_STATUSES.pendingApproval]),
      reject: (selectedEvent: EventInfoFragment | undefined) =>
        selected.length
          ? (
              tableData?.eventsList?.items.filter(({ id }) => selected.includes(id ?? '')) as
                | EventInfoFragment[]
                | undefined
            )?.every(admin =>
              checkEventStatus(admin, [EVENT_STATUSES.pendingApproval, EVENT_STATUSES.active]),
            )
          : checkEventStatus(selectedEvent, [
              EVENT_STATUSES.pendingApproval,
              EVENT_STATUSES.active,
            ]),
      viewDetails: !(selected.length > 1),
      duplicate: !(selected.length > 1),
    }),
    [selected, tableData?.eventsList?.items],
  );

  const switchStatus = useCallback(
    event => {
      const options: ChooseStatusChipOption[] = [];

      if (event.status === EVENT_STATUSES.pendingApproval) {
        options.push({
          value: EVENT_STATUSES.active,
          label: 'Approve',
          async onClick() {
            await onApproveEventClick(event.id as string);
          },
        });

        options.push({
          value: EVENT_STATUSES.inactive,
          label: 'Reject',
          async onClick() {
            await onRejectClick(event.id as string);
          },
        });
      }

      if (event.status === EVENT_STATUSES.active) {
        options.push({
          value: EVENT_STATUSES.inactive,
          label: 'Reject',
          async onClick() {
            await onRejectClick(event.id as string);
          },
        });
      }

      return <ChooseEventStatusChip status={event.status} options={options} />;
    },
    [onApproveEventClick, onRejectClick],
  );

  const eventsListData = useMemo(() => {
    const events = tableData?.eventsList?.items ?? [];

    return events.map(event => {
      const timezone = US_TIMEZONES.find(item => item.abbreviation === event.timezone);

      const eventDate = getFullMonthAndWeekdayAndTime(
        event.date,
        timezone?.abbreviation || DEFAULT_TIMEZONE,
      );

      return {
        id: event.id ?? '',
        name: event.title,
        date: eventDate,
        location: getEventAddress(event.location),
        createdBy: formatUserName(event.createdBy),
        createdAt: formatTableDate(event.createdAt),
        titleSponsor: `${event.titleSponsor?.name ?? 'No title sponsor found.'}`,
        status: switchStatus(event),
      };
    });
  }, [switchStatus, tableData?.eventsList?.items]);

  const eventsListTableActions = useMemo((): SpreadsheetCellActions => {
    const selectedEvent = tableData?.eventsList.items.find(event => event?.id === currentRowId) as
      | EventInfoFragment
      | undefined;

    const options: SpreadsheetCellActions = [];

    if (isActionsVisible.approve(selectedEvent)) {
      options.push({
        id: 'Approve Event',
        title: 'Approve Event',
        icon: <Icon name="CheckCircleOutline" />,
        onClickAction: async (eventId: string) => {
          if (selected.length > 1) {
            await Promise.all(selected.map(onApproveEventClick));
            return;
          }

          onApproveEventClick(eventId);
        },
      });
    }

    if (isActionsVisible.duplicate) {
      options.push({
        id: 'Duplicate Event',
        title: 'Duplicate Event',
        icon: <Icon name="EventNote" />,
        onClickAction: onDuplicateEventClick,
      });
    }

    if (isActionsVisible.viewDetails) {
      options.push({
        id: 'View Details',
        title: 'View Details',
        icon: <Icon name="Edit" />,
        onClickAction: onViewDetailsClick,
      });
    }

    if (isActionsVisible.reject(selectedEvent)) {
      options.push({
        id: 'Reject',
        title: 'Reject',
        icon: <Icon name="DeleteForever" />,
        onClickAction: async (eventId: string) => {
          if (selected.length > 1) {
            await Promise.all(selected.map(onRejectClick));
            return;
          }

          onRejectClick(eventId);
        },
      });
    }

    return options;
  }, [
    tableData?.eventsList.items,
    isActionsVisible,
    onDuplicateEventClick,
    onViewDetailsClick,
    currentRowId,
    selected,
    onApproveEventClick,
    onRejectClick,
  ]);

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: isEventDuplicateModalOpened,
          onClose: onModalClose,
          fullWidth: true,
        }}
        titleProps={{ title: wasEventEdited ? 'Event Details' : 'Duplicate Event' }}
      >
        <EventsCreateModal
          onModalClose={onModalClose}
          event={eventCopy}
          wasEventEdited={wasEventEdited}
        />
      </Modal>

      <Spreadsheet
        headlines={eventsListTableHeadlines}
        cellActions={eventsListTableActions}
        loading={tableLoading}
        toolbarOptions={{
          withDownload: true,
          withPerPage: true,
          filters: eventsSpreadsheetFilters,
          downloadHandler,
          rawData: tableData?.eventsList?.items ?? [],
        }}
        data={eventsListData}
        itemsCount={count}
      />
    </Fragment>
  );
}
