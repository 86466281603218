import React from 'react';

import { SpreadsheetContext, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'shared/features/spreadsheet';

export const DEFAULT_CONTEXT_VALUE: SpreadsheetContext = {
  loading: false,
  setLoading: () => {},

  page: DEFAULT_PAGE,
  setPage: () => {},

  filter: { query: {}, fields: {} },
  setFilter: () => {},

  pageSize: DEFAULT_PAGE_SIZE,
  setPageSize: () => {},

  sortOption: undefined,
  setSortOption: () => {},

  queryParams: {
    filter: {},
    skip: 0,
    first: DEFAULT_PAGE_SIZE,
    sort: {},
  },
  currentRowId: '',
  setCurrentRowId: () => {},

  selected: [],
  setSelected: () => {},

  customFilter: { query: {}, fields: {} },
  setCustomFilter: () => {},

  zip: { startPointZip: '', radius: '-' },
  setZip: () => {},

  chipsArray: {
    hobbies: [],
    clubs: [],
  },
  setChipsArray: () => {},

  rangeValues: '1959,2099',
  setRangeValues: () => {},

  softReset() {},

  reset() {},
};

export const SPREADSHEET_CONTEXT = React.createContext<SpreadsheetContext>(DEFAULT_CONTEXT_VALUE);
