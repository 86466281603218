import { Forum, GroupAdd, People } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { uniqBy } from 'ramda';

import {
  GroupInfoFragment,
  useGroupMemberRequestUpsertMutation,
  UserKeyFilter,
} from 'shared/graphql';

import { useGroupMembers } from 'features/groups/hooks';
import { useInboxContext } from 'providers/InboxProvider';

import { Buttons } from './ExplorerGroupCard.styles';
import { generateGroupRoute } from 'shared/routes';
import { useHistory } from 'react-router-dom';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { GroupUserRequestStatus } from '@jebel/constants';
import { useCurrentUser, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

export interface ExplorerGroupCardButtonOptions {
  /**
   * Whether to show the join button.
   * @default false
   */
  withJoin?: boolean;

  /**
   * Whether to show the message button.
   * @default false
   */
  withMessage?: boolean;

  /**
   * Whether to show the view profile button.
   * @default false
   */
  withViewProfile?: boolean;
}

export interface ExplorerGroupCardButtonsProps extends ExplorerGroupCardButtonOptions {
  data: GroupInfoFragment;
}

const REQUESTING_MEMBERSHIP_MESSAGE = 'Requesting membership, please stand by.';
const REQUESTING_MEMBERSHIP_MESSAGE_KEY = 'REQUESTING_MEMBERSHIP';

export function ExplorerGroupCardButtons({
  data: group,
  withJoin = false,
  withMessage = false,
  withViewProfile = false,
}: ExplorerGroupCardButtonsProps) {
  const { user } = useCurrentUser();
  const { data: members, isCurrentUserMember, isCurrentUserPending } = useGroupMembers(group.id);
  const { onOpenInboxModal } = useInboxContext();
  const { push: navigate } = useHistory();
  const { showMessage, showError, dismiss } = useToast();

  const [sendMembershipRequest, { loading: requestingMembership }] =
    useGroupMemberRequestUpsertMutation({
      refetchQueries: ['GroupMembers'],
      awaitRefetchQueries: true,
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success! You successfully requested to join the group.',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request was not sent.`,
      },
    });

  const showJoinButton = withJoin && !isCurrentUserMember;
  const showFeedButton = withViewProfile && isCurrentUserMember;

  const handleOpenFeed = () => {
    if (!group?.id) {
      return;
    }

    const url = generateGroupRoute(group.id);
    navigate(url);
  };

  const handleJoin = async () => {
    if (!group?.id || !user?.id) {
      return;
    }

    showMessage(REQUESTING_MEMBERSHIP_MESSAGE, { id: REQUESTING_MEMBERSHIP_MESSAGE_KEY });

    try {
      await sendMembershipRequest({
        variables: {
          userId: user.id,
          groupId: group.id,
          status: GroupUserRequestStatus.Pending,
        },
      });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      dismiss(REQUESTING_MEMBERSHIP_MESSAGE_KEY);
    }
  };

  const handleSendMessage = () => {
    if (!onOpenInboxModal) {
      return;
    }

    const users: UserKeyFilter[] = uniqBy(
      user => user?.id,
      members.map<UserKeyFilter>(manager => manager.user),
    );

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: users as UserKeyFilter[],
        messageType: 'personal',
      },
    });
  };

  return (
    <Buttons>
      {withMessage && (
        <Button
          size="small"
          color="primary"
          startIcon={<Forum fontSize="small" />}
          onClick={handleSendMessage}
        >
          Message
        </Button>
      )}

      {showJoinButton && (
        <Button
          size="small"
          color="primary"
          startIcon={<GroupAdd fontSize="small" />}
          onClick={handleJoin}
          disabled={requestingMembership || isCurrentUserPending}
        >
          Join
        </Button>
      )}

      {showFeedButton && (
        <Button
          size="small"
          color="primary"
          startIcon={<People fontSize="small" />}
          onClick={handleOpenFeed}
        >
          View Profile
        </Button>
      )}
    </Buttons>
  );
}
