import { useEffect } from 'react';
import { useQuery, DocumentNode, WatchQueryFetchPolicy, OperationVariables } from '@apollo/client';

import { createFilterBuilder } from '@jebel/utils';

import {
  SpreadsheetContextQueryVariables,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import { searchFilterGenerator } from 'shared/utils/search';

import { useSearchContext } from '../providers';

export type UseSpreadsheetSearchProps<V extends SpreadsheetContextQueryVariables> = {
  /**
   * If `true`, the search will be skipped.
   * This is useful when you want to skip the search when the search query is empty.
   * @default false
   */
  skip?: boolean;

  query: DocumentNode;
  /** Overrides the given context and setup extra fields. */
  queryVariables?: V;
  fetchPolicy?: WatchQueryFetchPolicy;
  searchingFields: string[];
};

/**
 * Custom hook to perform a spreadsheet search using GraphQL queries.
 *
 * @template Q - The type of the query result.
 * @template V - The type of the query variables, extending SpreadsheetContextQueryVariables.
 *
 * @param {UseSpreadsheetSearchProps<V>} props - The properties for the search hook.
 * @param {DocumentNode} props.query - The GraphQL query to execute.
 * @param {V} props.queryVariables - The variables for the GraphQL query.
 * @param {string[]} props.searchingFields - The fields to search within.
 * @param {FetchPolicy} props.fetchPolicy - The fetch policy for the query.
 */
export function useSpreadsheetSearch<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Q = any,
  V extends SpreadsheetContextQueryVariables = SpreadsheetContextQueryVariables,
>({ skip, query, queryVariables, searchingFields, fetchPolicy }: UseSpreadsheetSearchProps<V>) {
  const { searchQuery } = useSearchContext();
  const { filter: contextFilter, setFilter } = useSpreadsheetContext();

  useEffect(() => {
    const filter = createFilterBuilder();
    const searchFilter = searchFilterGenerator(searchQuery, searchingFields);

    if (searchFilter) {
      filter.and(searchFilter);
    }

    setFilter({
      query: filter.build(),
      fields: contextFilter.fields,
    });
  }, [searchQuery]);

  const variables: OperationVariables = {
    ...queryVariables,
    filter: queryVariables?.filter ?? contextFilter.query,
  };

  const {
    data: tableData,
    loading: tableLoading,
    refetch: refetchTable,
    fetchMore: fetchMoreTable,
  } = useQuery<Q>(query, {
    skip,
    fetchPolicy,
    variables: variables as V,
  });

  return {
    tableData,
    tableLoading: tableLoading && !tableData,
    refetchTable,
    fetchMoreTable,
    queryVariables: variables,
  };
}
