import { Fragment, useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useSpreadsheetContext } from 'shared/features/spreadsheet';

interface SpreadsheetPreloaderProps {
  columnsCount: number;
}

export function SpreadsheetPreloader(props: SpreadsheetPreloaderProps) {
  const { pageSize } = useSpreadsheetContext();

  const rows = useMemo(() => {
    return Array.from({ length: pageSize }, (_, index) => index);
  }, [pageSize]);

  return (
    <Fragment>
      {rows.map(index => (
        <SpreadsheetPreloaderRow key={index} {...props} />
      ))}
    </Fragment>
  );
}

function SpreadsheetPreloaderRow({ columnsCount }: SpreadsheetPreloaderProps) {
  return (
    <TableRow>
      {Array.from({ length: columnsCount }, (_, index) => (
        <TableCell key={index}>
          <Skeleton width="90%" style={{ minWidth: '2rem' }} />
        </TableCell>
      ))}
    </TableRow>
  );
}
