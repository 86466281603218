import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';

import { ClassScorecardContent } from '../components/ClassScorecardContent';

export function ClassScorecardPage() {
  return (
    <SearchProvider>
      <RouteLayout content={<ClassScorecardContent />} />
    </SearchProvider>
  );
}
