import { useState } from 'react';
import { Box, Button, css } from '@mui/material';

import { LegacyFeedPostModalCreate } from 'features/legacy/components/LegacyFeedPostModal';
import { Typography } from 'shared/components/ui';
import { CardLayout } from 'shared/components/layouts';
import { SearchControl } from 'shared/features/search';

import { LEGACY_SORT_INFO } from '../constants';
import { LegacyPostsList } from './LegacyPostsList';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const headerCSS = css`
  padding: 2rem;
`;

export function LegacyContent() {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onModalClose = () => {
    setShowCreateModal(false);
  };

  const onModalOpen = () => {
    setShowCreateModal(true);
  };

  return (
    <Box css={containerCSS}>
      <CardLayout css={headerCSS}>
        <LegacyFeedPostModalCreate isOpen={showCreateModal} onClose={onModalClose} />

        <Box display="flex" flexDirection="column" justifyContent="start">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="primary" variant="subtitle2">
              Legacy
            </Typography>

            <Box display="flex" columnGap={2}>
              <Button onClick={onModalOpen} color="primary" disableElevation variant="contained">
                Post
              </Button>
            </Box>
          </Box>

          <Box mt={3}>
            <SearchControl
              withTextField
              withDateFilterField
              dateFilterFieldProps={{ label: 'Date' }}
              withSortField
              textFieldProps={{ placeholder: 'Search Posts' }}
              sortFieldProps={LEGACY_SORT_INFO}
              elementsWidth={['1fr', '0.8fr', '1fr']}
            />
          </Box>
        </Box>
      </CardLayout>

      <LegacyPostsList />
    </Box>
  );
}
