import { gql } from '@apollo/client';

export const EVENTS_COUNT_QUERY = gql`
  query EventsCount($filter: EventFilter!) {
    total: eventsList(filter: $filter) {
      count
    }

    upcoming: eventsList(
      filter: {
        AND: [
          $filter
          {
            status: { equals: "active" }
            isApproved: { equals: true }
            date: { relative: { gte: { op: ADD, value: "1", unit: SECOND } } }
          }
        ]
      }
    ) {
      count
    }

    pending: eventsList(filter: { AND: [$filter, { status: { equals: "pendingApproval" } }] }) {
      count
    }
  }
`;

export const EVENT_STATUS_UPDATE = gql`
  mutation EventStatusUpdate($data: EventStatusUpdateRequestInput!) {
    eventStatusUpdateRequest(data: $data) {
      success
    }
  }
`;

export const EVENT_DELETE_MUTATION = gql`
  mutation EventDelete($data: EventDeleteInput!) {
    eventDelete(data: $data) {
      success
    }
  }
`;
