import { useMemo } from 'react';

import { createFilterBuilder, isStr } from '@jebel/utils';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import {
  useCreateGraduatingYearMutation,
  useEditGraduatingYearMutation,
  useDeleteGraduatingYearMutation,
  GraduatingYearsMetricsQuery,
  GraduatingYearsMetricFilter,
} from 'shared/graphql';
import { useSpreadsheetContext } from 'shared/features/spreadsheet';
import { useSearchContext, useSpreadsheetSearch } from 'shared/features/search';
import { GRADUATING_YEARS_METRICS_QUERY } from 'features/scorecard/queries';

import { useSchoolConfiguration } from './useSchoolConfiguration';
import { createUserFilter } from 'features/search/utils';

export function useGraduatingYearsSpreadsheetInfo() {
  const { filter: yearsInitialFilter, queryParams } = useSpreadsheetContext();
  const { data: school } = useSchoolConfiguration();
  const { searchQuery } = useSearchContext();

  const yearsFilter = useMemo(() => {
    const filter = createFilterBuilder<GraduatingYearsMetricFilter>(yearsInitialFilter.query);
    const number = Number(searchQuery);

    if (searchQuery && isNaN(number)) {
      const userFilter = createUserFilter({ school, search: searchQuery });

      filter.and({ createdBy: userFilter });
    }

    if (searchQuery && !isNaN(number)) {
      filter.or({ year: { equals: String(number) } });
      filter.or({ expectedUsersCount: { equals: number } });
      filter.or({ missingUsersCount: { equals: number } });
    }

    if (school) {
      // Filter by current school.
      // https://github.com/jebelapp/jebel/issues/1684
      filter.and({ school: { equals: school.id } });
    }

    return filter.build();
  }, [yearsInitialFilter, searchQuery]);

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<GraduatingYearsMetricsQuery>({
      query: GRADUATING_YEARS_METRICS_QUERY,
      searchingFields: [],
      queryVariables: {
        ...queryParams,
        filter: yearsFilter,
      },
    });

  const [createGraduatingYear, { loading: creating }] = useCreateGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been created',
      [SNACKBAR_ERROR_MESSAGE]: `Error! This class already exists in the Class Scorecard. Please review and try again`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  const [editGraduatingYear, { loading: editing }] = useEditGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been edited successfully',
      [SNACKBAR_ERROR_MESSAGE]: `Error while editing class!`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  const [deleteGraduatingYear, { loading: deleting }] = useDeleteGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been deleted',
      [SNACKBAR_ERROR_MESSAGE]: `Error while deleting class!`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  return {
    tableData,
    tableLoading,
    createGraduatingYear,
    creating,
    editGraduatingYear,
    editing,
    deleteGraduatingYear,
    deleting,
    queryVariables,
  };
}
