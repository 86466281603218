import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { BoxProps, Paper, TableContainer, Table, TableBody } from '@material-ui/core';

import {
  HeadlinesType,
  SpreadsheetDataType,
  SpreadsheetCellActions,
  useSpreadsheetContext,
  SpreadsheetRow,
  SpreadsheetToolbar,
  SpreadsheetHeader,
  SpreadsheetPagination,
  SpreadsheetToolbarOptions,
} from 'shared/features/spreadsheet';

import { SpreadsheetPreloader } from './SpreadsheetPreloader';
import { SpreadsheetEmpty } from './SpreadsheetEmpty';

const paperCSS = css`
  width: 100%;
  overflow: hidden;
`;

const tableContainerCSS = css``;

export type SpreadsheetProps<T extends HeadlinesType> = {
  headlines: T;
  data: SpreadsheetDataType<T>[];
  cellActions?: SpreadsheetCellActions;
  containerProps?: BoxProps;
  itemsCount: number;
  loading?: boolean;
  toolbarOptions?: SpreadsheetToolbarOptions;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
};

/**
 * Use useSpreadsheetContext with this component
 * Use useSpreadsheetSearch for search
 * All headlines fields must be in data
 * In data fields 'id' and 'createdAt' is required
 */

export const Spreadsheet = <T extends HeadlinesType>({
  headlines,
  data,
  cellActions,
  containerProps,
  loading,
  toolbarOptions,
  itemsCount = data.length || 0,
  toolbarHeader,
  textToolbarHeader,
}: SpreadsheetProps<T>) => {
  const { page, pageSize, selected, setSelected, setLoading } = useSpreadsheetContext();

  const withCheckboxes = toolbarOptions?.withCheckboxes ?? true;

  useEffect(() => {
    setLoading(loading ?? false);
  }, [loading]);

  const onSelect = useCallback(
    (id: string) => () => {
      setSelected(selection => {
        const isSelected = selection.includes(id);

        if (isSelected) {
          return selection.filter(selectedId => selectedId !== id);
        }

        return [...selection, id];
      });
    },
    [setSelected],
  );

  const onSelectAll = useCallback(() => {
    setSelected(prev => {
      const selectedPage = data.filter(row => prev.includes(row.id));

      if (selectedPage.length === pageSize) {
        return [];
      }

      return selected.concat(data.map(row => row.id));
    });
  }, [data, pageSize, setSelected]);

  const onClickMainAction = useCallback(() => {
    const onClick = toolbarOptions?.mainToolbarAction?.onClick;

    onClick?.(selected ?? []);
  }, [selected, toolbarOptions]);

  const tableContent = useMemo(() => {
    const columnsCount = headlines.length + (cellActions?.length === 0 ? 1 : 2);

    if (loading) {
      return <SpreadsheetPreloader columnsCount={columnsCount} />;
    }

    if (data.length === 0) {
      return <SpreadsheetEmpty columnsCount={columnsCount} />;
    }

    return (
      <Fragment>
        {data.map((row, index) => {
          const isSelected = selected?.some(selectedId => selectedId === row.id);

          return (
            <SpreadsheetRow
              withCheckbox={withCheckboxes}
              rowData={row}
              cellActions={cellActions}
              headlines={headlines}
              key={row.id}
              isSelected={isSelected}
              onSelect={onSelect(row.id)}
              withIndex={!!toolbarOptions?.withIndex}
              index={index + (page - 1) * pageSize}
              howManySelected={selected?.length ?? 0}
            />
          );
        })}
      </Fragment>
    );
  }, [
    cellActions,
    data,
    headlines,
    loading,
    onSelect,
    page,
    pageSize,
    selected,
    withCheckboxes,
    toolbarOptions?.withIndex,
  ]);

  return (
    <Paper css={paperCSS}>
      <SpreadsheetToolbar
        itemsCount={itemsCount}
        toolbarOptions={toolbarOptions}
        onSelectAll={onSelectAll}
        onClickMainAction={onClickMainAction}
        toolbarHeader={toolbarHeader}
        textToolbarHeader={textToolbarHeader}
      />

      <TableContainer css={tableContainerCSS} {...containerProps}>
        <Table stickyHeader aria-label="sticky header">
          <SpreadsheetHeader
            headlines={headlines}
            withActions={!!cellActions?.length}
            withCheckbox={withCheckboxes}
            withIndex={!!toolbarOptions?.withIndex}
          />

          <TableBody>{tableContent}</TableBody>
        </Table>
      </TableContainer>

      {toolbarOptions?.withPerPage && <SpreadsheetPagination itemsCount={itemsCount} />}
    </Paper>
  );
};
