import { useMemo } from 'react';

import { createFilterBuilder } from '@jebel/utils';

import {
  useTrendingSearchesAddWordMutation,
  useTrendingSearchesUpdateWordCountMutation,
  useTrendingSearchesListQuery,
  TrendingSearchUpdateInput,
  TrendingSearchCreateInput,
  TrendingSearchFilter,
  useTrendingSearchesListLazyQuery,
} from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';
import { FilterType } from 'shared/features/spreadsheet';

/** @deprecated DO NOT USE, THIS IS A TERRIBLE IDEA. */
export const useTrendingSearchesFilter = ({
  currentFilter,
  filterByCount,
  filterByWord,
  dateRange,
}: {
  currentFilter?: FilterType | null;
  filterByCount: string;
  filterByWord: string;
  dateRange: { from: any; to: any };
}) => {
  const startDate = dateRange.from;
  const endDate = dateRange.to;

  return useMemo(() => {
    const filter = createFilterBuilder<TrendingSearchFilter>(currentFilter);

    if (filterByCount) {
      filter.and({ searchesCount: { gte: Number(filterByCount) } });
    }

    if (filterByWord) {
      filter.and({ wordOrPhrase: { contains: filterByWord } });
    }

    if (startDate) {
      filter.and({ createdAt: { gte: startDate } });
    }

    if (endDate) {
      filter.and({ createdAt: { lte: endDate } });
    }

    return filter.build();
  }, [currentFilter, filterByCount, filterByWord, startDate, endDate]);
};

/** @deprecated This is a bad practice, please DO NOT use. */
export const useTrendingSearchesList = () => {
  const { data, loading, ...rest } = useTrendingSearchesListQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    trendingSearchesList: data?.trendingSearchesList?.items ?? [],
    loading: loading && !data,
    ...rest,
  };
};

export const useTrendingSearchesUpdate = () => {
  const [updateSearchingWord] = useTrendingSearchesUpdateWordCountMutation();

  const onUpdateSearchingWord = async (data: TrendingSearchUpdateInput) => {
    if (!data?.id) {
      return;
    }

    await updateSearchingWord({ variables: { data } });
  };

  return {
    onUpdateSearchingWord,
  };
};

export const useTrendingSearchesCreate = () => {
  const { onUpdateSearchingWord } = useTrendingSearchesUpdate();

  const [findManyTrendingSearches] = useTrendingSearchesListLazyQuery();
  const [addSearchingWord] = useTrendingSearchesAddWordMutation();

  const onAddSearchingWord = async (data: TrendingSearchCreateInput) => {
    if (!data?.wordOrPhrase) {
      return;
    }

    const trendingSearchesResponse = await findManyTrendingSearches({
      variables: {
        filter: {
          wordOrPhrase: { equals: data.wordOrPhrase },
        },
      },
    });

    const [trendingSearch] = trendingSearchesResponse.data?.trendingSearchesList.items ?? [];

    try {
      if (trendingSearch?.id) {
        const previousCount = trendingSearch?.searchesCount ?? 0;
        const newCount = data.searchesCount ?? 1;

        await onUpdateSearchingWord({
          id: trendingSearch.id,
          searchesCount: previousCount + newCount,
        });

        return;
      }

      await addSearchingWord({
        variables: {
          data: { searchesCount: 1, wordOrPhrase: data?.wordOrPhrase },
        },
      });
    } catch (err) {
      sendToSentry(err);
    }
  };

  return {
    onAddSearchingWord,
  };
};
