import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import styled from '@emotion/styled';

import { SORT } from '@jebel/constants';

import { Typography } from 'shared/components/ui';
import { SpreadsheetStats } from 'shared/components/ui/SpreadsheetStats';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { useModalState } from 'shared/hooks';

import { useScorecardStats } from '../hooks/useScorecardStats';
import { ClassScorecardSpreadsheet } from './ClassScorecardSpreadsheet';
import { CreateGraduatingYearModal } from './CreateGraduatingYearModal';
import { ClassViewDedailsModal } from './ClassViewDedailsModal';
import { EditGraduatingYearModal } from './EditGraduatingYearModal';

const ClassScorecardLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0px;
`;

const FALLBACK_SORT = {
  sort: { year: SORT.desc },
  order: SORT.desc,
  headcellId: 'year',
};

export function ClassScorecardContent() {
  const {
    show: isNewClassModalOpen,
    open: onNewClassModalOpen,
    close: onNewClassModalClose,
  } = useModalState();

  const {
    show: isViewClassModalOpen,
    params: selectedClassYear,
    open: onViewClassModalOpen,
    close: onViewClassModalClose,
  } = useModalState<string>();

  const {
    show: isEditClassModalOpen,
    params: currentClassIdToEdit,
    open: onEditClassModalOpen,
    close: onEditClassModalClose,
  } = useModalState<string>();

  const {
    averageCompletion,
    classesCount,
    usersWithClassesCount,
    usersWithoutClassesCount,
    loading: isScorecardStatsLoading,
    refetchScorecardStats,
  } = useScorecardStats();

  return (
    <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
      <CreateGraduatingYearModal
        isOpen={isNewClassModalOpen}
        onModalClose={onNewClassModalClose}
        onCreate={refetchScorecardStats}
      />

      {currentClassIdToEdit && (
        <EditGraduatingYearModal
          classId={currentClassIdToEdit}
          isOpen={isEditClassModalOpen}
          onModalClose={onEditClassModalClose}
          onEdit={refetchScorecardStats}
        />
      )}

      {selectedClassYear && (
        <Dialog maxWidth="sm" fullWidth open={isViewClassModalOpen} onClose={onViewClassModalClose}>
          <DialogTitle>Graduating Year: {selectedClassYear}</DialogTitle>

          <DialogContent dividers>
            <ClassViewDedailsModal year={selectedClassYear} />
          </DialogContent>

          <DialogActions>
            <Button onClick={onViewClassModalClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <ClassScorecardLayout>
        <Header>
          <Typography variant="subtitle2" color="primary">
            Class Scorecard
          </Typography>

          <Button
            size="medium"
            variant="contained"
            color="secondary"
            onClick={onNewClassModalOpen}
            disabled={isScorecardStatsLoading}
            disableElevation
          >
            New Class
          </Button>
        </Header>

        <SpreadsheetStats
          loading={isScorecardStatsLoading}
          data={[
            { value: classesCount, label: 'Total Classes' },
            { value: usersWithClassesCount, label: 'Total Class Members' },
            { value: usersWithoutClassesCount, label: 'Users Without Classes' },
            {
              value: `${averageCompletion}%`,
              label: 'Average Class Completion',
              color: 'secondary',
            },
          ]}
        />

        <ClassScorecardSpreadsheet
          refetchStats={refetchScorecardStats}
          loading={isScorecardStatsLoading}
          onViewClassModalOpen={onViewClassModalOpen}
          onEditClassModalOpen={onEditClassModalOpen}
        />
      </ClassScorecardLayout>
    </SpreadsheetProvider>
  );
}
