import styled from '@emotion/styled';

export const Container = styled.div`
  --icons-modal-width: 30rem;
  --icons-modal-height: 30rem;

  display: flex;
  flex-direction: column;
  width: var(--icons-modal-width);
  height: var(--icons-modal-height);
  gap: 1rem;

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 0 1rem;
`;

export const EmojiContainer = styled.button`
  --emoji-container-size: 40px;

  width: var(--emoji-container-size);
  height: var(--emoji-container-size);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
