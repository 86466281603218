import { useMemo } from 'react';

import { POST_REPORT_STATUS, HomeFeedItemType, HOME_FEED_ITEM_TYPES } from '@jebel/constants';
import { extractHomeFeedItemMedia, fileManyRelations, formatSQLDate } from '@jebel/utils';

import {
  GroupPostUpdateInput,
  HomeFeedItemFragment,
  HomeFeedPostUpdateInput,
  LegacyFeedPostsListItemFragment as LegacyFeedPost,
  LegacyFeedPostUpdateInput,
  PostMentionKeyFilter,
  SchoolNewsPostsListItemFragment as SchoolNewsPost,
  SchoolNewsPostUpdateInput,
} from 'shared/graphql';
import { PostModalCreate, PostModalCreateForm } from 'shared/features/posts';
import { prepareMentionsCreateInput } from 'shared/features/mentions/utils';

import { useHomeFeedPostUpdate } from '../../hooks';
import { useLegacyFeedPostUpdate } from '../../../legacy/hooks/useLegacyFeedPostUpdate';
import { useSchoolNewsPostUpdate } from '../../../news/hooks';
import { useGroupPostUpdate } from '../../../groups/hooks';
import { recordError } from 'shared/utils/record';
import { useToast } from 'shared/hooks';

interface Props {
  selectedPost: HomeFeedItemFragment | undefined;

  isOpen: boolean;
  onClose(): void;
}

type UpdateInput =
  | HomeFeedPostUpdateInput
  | GroupPostUpdateInput
  | LegacyFeedPostUpdateInput
  | SchoolNewsPostUpdateInput;

export function HomeFeedPostModalUpdate({ isOpen, onClose, selectedPost }: Props) {
  const { onPostUpdate: updateHomeFeedPost } = useHomeFeedPostUpdate();
  const { onPostUpdate: updateLegacyFeedPost } = useLegacyFeedPostUpdate();
  const { onPostUpdate: updateSchoolNewsPost } = useSchoolNewsPostUpdate();
  const { onPostUpdate: updateGroupPost } = useGroupPostUpdate();
  const { showError } = useToast();

  const withSchedule = selectedPost?.postType === 'SchoolPost';
  const withPinned = selectedPost?.postType === 'SchoolPost';
  const withDatePicker = selectedPost?.postType === 'LegacyPost';

  const handleSubmit = async (form: PostModalCreateForm) => {
    const trimmedText = form.text && form.text.trim();

    const payload: UpdateInput = {
      id: selectedPost?.id as string,
      text: trimmedText as string,
      isPinned: Boolean(form.isPinned),
      commentsAllowed: form.commentsAllowed,
      media: fileManyRelations(form?.media, initial.media),
      reportStatus: POST_REPORT_STATUS.pendingReview,
      postDate: form.postDate,
    };

    if (form.mentions) {
      const create = form.mentions?.map(prepareMentionsCreateInput);
      const mentions: PostMentionKeyFilter[] = selectedPost?.mentions?.items ?? [];
      const disconnect = mentions.map<PostMentionKeyFilter>(mention => ({ id: mention.id }));

      payload.mentions = { create, disconnect };
    }

    try {
      const type = selectedPost?.postType as HomeFeedItemType;

      if (type === HOME_FEED_ITEM_TYPES.HOME) {
        await updateHomeFeedPost(payload);
      }

      if (type === HOME_FEED_ITEM_TYPES.LEGACY) {
        if (form.legacyDate) {
          Object.assign(payload, { legacyDate: formatSQLDate(form.legacyDate) });
        }

        await updateLegacyFeedPost(payload);
      }

      if (type === HOME_FEED_ITEM_TYPES.SCHOOL) {
        await updateSchoolNewsPost(payload);
      }

      if (type === HOME_FEED_ITEM_TYPES.GROUP) {
        await updateGroupPost(payload);
      }

      onClose();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const initial = useMemo<PostModalCreateForm>(() => {
    const media = extractHomeFeedItemMedia(selectedPost?.media);

    return {
      commentsAllowed: Boolean(selectedPost?.commentsAllowed),
      isPinned: Boolean(selectedPost?.isPinned),
      text: selectedPost?.text ?? '',
      media: media?.items ?? [],
      postDate: withSchedule ? (selectedPost as SchoolNewsPost).postDate : null,
      legacyDate: withDatePicker ? (selectedPost as LegacyFeedPost)?.legacyDate : undefined,
    };
  }, [selectedPost, withDatePicker, withSchedule]);

  return (
    <PostModalCreate
      isEdit
      extraFields={{ legacyDate: withDatePicker }}
      withSchedule={withSchedule}
      withPinned={withPinned}
      initialValues={initial}
      onPostCreate={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
