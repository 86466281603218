import { useGraduatingYearsMetricsQuery, UserFilter, useUsersListCountQuery } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';
import { createFilterBuilder } from '@jebel/utils';
import { useMemo } from 'react';

export function useScorecardStats() {
  const { data: school } = useSchoolConfiguration();

  const {
    data: classesResponse,
    loading: isClassesDataLoading,
    refetch: refetchClassesData,
  } = useGraduatingYearsMetricsQuery({
    skip: !school,
    variables: {
      filter: {
        // https://github.com/jebelapp/jebel/issues/1684
        school: { equals: school?.id },
      },
    },
  });

  const createMembersFilter = ({ hasGraduatingYear } = { hasGraduatingYear: true }) => {
    const filter = createFilterBuilder<UserFilter>({
      graduatingYear: { is_not_empty: hasGraduatingYear },
    });

    if (school) {
      filter.and({
        // https://github.com/jebelapp/jebel/issues/1684
        schoolConfigurations: {
          some: { id: { equals: school.id } },
        },
      });
    }

    return filter.build();
  };

  const {
    data: membersWithClassResponse,
    loading: membersWithClassLoading,
    refetch: refetchMembersWithClass,
  } = useUsersListCountQuery({
    skip: !school,
    variables: {
      filter: createMembersFilter(),
    },
  });

  const {
    data: membersWithoutClass,
    loading: membersWithoutClassLoading,
    refetch: refetchMembersWithoutClass,
  } = useUsersListCountQuery({
    skip: !school,
    variables: {
      filter: createMembersFilter({ hasGraduatingYear: false }),
    },
  });

  const loading = isClassesDataLoading || membersWithClassLoading || membersWithoutClassLoading;

  const totalClassMembers = membersWithClassResponse?.users?.count ?? 0;
  const totalMembersWithoutClass = membersWithoutClass?.users?.count ?? 0;

  const classes = useMemo(() => classesResponse?.metrics.items ?? [], [classesResponse]);

  const graduatingYears = useMemo(() => {
    return classes.map(({ year }) => Number(year));
  }, [classes]);

  const averageCompletion = useMemo(() => {
    if (classes.length === 0) {
      return 0;
    }

    let total = 0;

    for (const year of classes) {
      total += year.registeredUsersPercentage ?? 0;
    }

    const average = total / classes.length;
    return average.toFixed(1);
  }, [classes]);

  const refetchScorecardStats = async () => {
    await Promise.all([
      refetchClassesData(),
      refetchMembersWithClass(),
      refetchMembersWithoutClass(),
    ]);
  };

  return {
    loading,
    graduatingYears: graduatingYears,
    usersWithClassesCount: totalClassMembers,
    usersWithoutClassesCount: totalMembersWithoutClass,
    classesCount: graduatingYears.length,
    averageCompletion,
    refetchScorecardStats,
  };
}
