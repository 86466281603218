import { Box } from '@material-ui/core';
import { Theme, css } from '@emotion/react';

import { GroupInfoFragment } from 'shared/graphql';
import { useSearchContext } from 'shared/features/search';
import { GroupListItem } from 'features/groups/components/GroupListItem';

const gridLayoutCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;

  ${theme.breakpoints.up('xl')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const listLayoutCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;

interface Props {
  groups: GroupInfoFragment[];
  isCreator?: boolean;

  onShowDetails?(group: GroupInfoFragment): void;
}

export function GroupsList(props: Props) {
  const { displayList } = useSearchContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr 1fr"
      gridTemplateRows="auto"
      gridRowGap={25}
      css={displayList ? listLayoutCSS : gridLayoutCSS}
    >
      {props.groups.map(group => (
        <GroupListItem
          key={group?.id}
          data={group}
          showJoined={false}
          showSettings
          isCreator={props.isCreator}
          onShowDetails={() => props.onShowDetails?.(group)}
        />
      ))}
    </Box>
  );
}
