import { useMemo } from 'react';

import { SpreadsheetStatsProps } from 'shared/components/ui/SpreadsheetStats';
import { useDiscountsStatsQuery } from 'shared/graphql';

/**
 * Custom hook to fetch and compute discount statistics.
 *
 * This hook uses the `useDiscountsStatsQuery` to fetch discount data and computes
 * various statistics such as active discounts, total redeemed discounts, and pending
 * discounts. The computed statistics are memoized for performance optimization.
 */
export function useDiscountsStats() {
  const { data: response, loading, refetch } = useDiscountsStatsQuery();

  const activeDiscounts = useMemo(() => response?.activeDiscounts.count ?? 0, [response]);

  const totalRedeemed = useMemo(() => {
    let total = 0;
    const discounts = response?.totalDiscounts.items || [];

    for (const discount of discounts) {
      total += discount.count ?? 0;
    }

    return total;
  }, [response]);

  const pendingDiscounts = useMemo(() => response?.pendingDiscounts.count ?? 0, [response]);

  const discountStats: SpreadsheetStatsProps['data'] = useMemo(() => {
    return [
      { label: 'Total Active Discounts', value: activeDiscounts },
      { label: 'Total Discounts Redeemed (last 30 days)', value: totalRedeemed },
      { label: 'Pending Approval', value: pendingDiscounts, color: 'secondary' },
    ];
  }, [activeDiscounts, totalRedeemed, pendingDiscounts]);

  return {
    discountStats,
    loading,
    refetchDiscountsStats: refetch,
  };
}
