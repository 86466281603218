import React from 'react';
import { Box, MenuItem, css, styled, Autocomplete, TextField, Slider } from '@mui/material';
import { Chip } from '@material-ui/core';
import { FormikState } from 'formik';

import { Button, Form, FormSelect, FormTextField, Typography } from 'shared/components/ui';
import {
  SpreadsheetFiltersType,
  useSpreadsheetContext,
  SpreadsheetContext,
  FilterAutocompleteType,
} from 'shared/features/spreadsheet';
import { FormError } from 'shared/components/form';

import { SELECT_OPTION_ALL } from '../../../../constants';

const chipCSS = (theme: any) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
`;

const activitiesInputCss = theme => css`
  fieldset {
    border-color: ${theme.palette.primary.dark};
  }
`;

export type FormData = {
  [x: string]: string | null | undefined;
};

export type SpreadsheetFilterContentProps = {
  filters: SpreadsheetFiltersType;
  handlePopoverClose: () => void;
};

export const SpreadsheetFilterContent: React.FC<SpreadsheetFilterContentProps> = ({
  filters,
  handlePopoverClose,
}) => {
  const {
    filter: currentFilters,
    setFilter,
    customFilter: currentCustomFilters,
    setCustomFilter,
    setZip,
    chipsArray,
    setChipsArray,
    rangeValues,
    setRangeValues,
  } = useSpreadsheetContext();

  const [error, setError] = React.useState(false);
  const [localChipsArray, setLocalChipsArray] = React.useState<{
    hobbies: string[];
    clubs: string[];
  }>(chipsArray);
  const [localRangeValues, setLocalRangeValues] = React.useState<number[]>(
    rangeValues.split(',').map(edge => Number(edge)),
  );

  const onActivityAdd = React.useCallback(
    (event: React.SyntheticEvent, value: string[], fieldName: string) => {
      if (error) {
        setError(false);
      }

      if (fieldName === 'hobbies') {
        setLocalChipsArray({
          ...localChipsArray,
          hobbies: value,
        });
      } else {
        setLocalChipsArray({
          ...localChipsArray,
          clubs: value,
        });
      }
    },
    [error, localChipsArray],
  );

  const onFocusInput = React.useCallback(() => {
    if (error) {
      setError(false);
    }
  }, [error]);

  const onChangeInputText = React.useCallback(() => {
    if (error) {
      setError(false);
    }
  }, [error]);

  const handleKeyDown = React.useCallback(
    (event, type, field: FilterAutocompleteType) => {
      const newValue = field.variants.includes(event.target.value) && event.target.value;
      if (!newValue) {
        return;
      }
      switch (event.key) {
        case ',':
        case ' ': {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.length > 0) {
            if (field.name === 'hobbies') {
              setLocalChipsArray({
                ...localChipsArray,
                hobbies: [...localChipsArray.hobbies, newValue],
              });
            } else {
              setLocalChipsArray({
                ...localChipsArray,
                clubs: [...localChipsArray.clubs, newValue],
              });
            }
          } else {
            setError(true);
          }
          break;
        }
        default:
      }
    },
    [localChipsArray],
  );

  const onSubmitFilter = React.useCallback(
    async (formData: Record<string, unknown>) => {
      setRangeValues(localRangeValues.join(','));
      setChipsArray(localChipsArray);
      const data = formData as FormData;
      const newCustomFilter: SpreadsheetContext['customFilter'] = filters
        .filter(({ customFilterPath }) => customFilterPath ?? undefined)
        .reduce(
          (newFilter, filter) => {
            const filterValue = data[filter.name];

            if (filter.name === 'distance') {
              setZip({ startPointZip: data?.zip ?? '', radius: filterValue ?? '' });
            }

            if (filter.name === 'zip') {
              setZip({ radius: data?.distance ?? '', startPointZip: filterValue ?? '' });
            }

            if (!filterValue || !filter.customFilterPath) {
              return newFilter;
            }

            return {
              query: { ...newFilter.query, ...filter.customFilterPath(filterValue) },
              fields: { ...newFilter.fields, [filter.name]: filterValue },
            };
          },
          { query: {}, fields: {} },
        );

      const newFilter: SpreadsheetContext['filter'] = filters
        .filter(({ filterPath }) => filterPath)
        .reduce(
          (newFilter, filter) => {
            if (filter.type === 'range') {
              const filterValue = localRangeValues;
              if (!filterValue || !filter.filterPath) {
                return newFilter;
              }

              return {
                query: { ...newFilter.query, ...filter.filterPath(filterValue.join(',')) },
                fields: { ...newFilter.fields, [filter.name]: filterValue },
              };
            }

            const filterValue = data[filter.name];
            if (filterValue === undefined || filterValue === null || !filter.filterPath) {
              return newFilter;
            }

            return {
              query: { ...newFilter.query, ...filter.filterPath(filterValue) },
              fields: { ...newFilter.fields, [filter.name]: filterValue },
            };
          },
          { query: {}, fields: {} },
        );

      setFilter({ ...newFilter });
      setCustomFilter(newCustomFilter);
      handlePopoverClose();
    },
    [
      setRangeValues,
      localRangeValues,
      setChipsArray,
      localChipsArray,
      filters,
      setFilter,
      setCustomFilter,
      handlePopoverClose,
      setZip,
    ],
  );

  const initialValues: FormData = React.useMemo(
    () => ({
      ...currentFilters.fields,
      ...currentCustomFilters.fields,
    }),
    [currentCustomFilters.fields, currentFilters.fields],
  );

  const onClose = () => {
    handlePopoverClose();
  };

  const onReset = React.useCallback(
    (
        resetForm: (nextState?: Partial<FormikState<Record<string, any>>> | undefined) => void,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
      ) =>
      () => {
        const initValues = Object.keys(initialValues).map(value => ({ [value]: '' }));
        resetForm({ values: { ...initValues } });
        setLocalChipsArray({
          hobbies: [],
          clubs: [],
        });
        filters?.find(({ name }) => name === 'distance') && setFieldValue('distance', '-');
        filters?.find(({ name }) => name === 'years') && setLocalRangeValues([1959, 2099]);
        filters?.find(({ name }) => name === 'zip') && setFieldValue('zip', '');
      },
    [filters, initialValues],
  );

  const onDeleteChip = React.useCallback(
    (fieldName: string, option: string) => () =>
      setLocalChipsArray({
        ...localChipsArray,
        [fieldName]: localChipsArray[fieldName].filter(label => label !== option),
      }),
    [localChipsArray],
  );

  return (
    <React.Fragment>
      <Box css={filterBoxCSS}>
        <Typography variant="h6">Filter by</Typography>
        <Form oldCss={filterFormCss} onSubmit={onSubmitFilter} initialValues={initialValues}>
          {({ resetForm, setFieldValue }) => (
            <React.Fragment>
              {filters?.map(field => {
                if (field.type === 'input') {
                  return (
                    <FilterElement size={field.size}>
                      <FormTextField
                        inputProps={{
                          autoComplete: 'off',
                          label: field.label,
                          variant: 'outlined',
                        }}
                        fieldProps={{ name: field.name }}
                      />
                    </FilterElement>
                  );
                }

                if (field.type === 'select') {
                  return (
                    <FilterElement size={field.size}>
                      <FormSelect
                        selectProps={{
                          variant: 'outlined',
                          label: field.label,
                          defaultValue: '',
                          displayEmpty: true,
                          children: [
                            { value: '', label: SELECT_OPTION_ALL },
                            ...field.variants,
                          ].map(variant => (
                            <MenuItem key={variant.text} value={variant.value as any}>
                              {variant.label ?? variant.text ?? variant.value}
                            </MenuItem>
                          )),
                        }}
                        fieldProps={{ name: field.name }}
                      />
                    </FilterElement>
                  );
                }

                if (field.type === 'range') {
                  return (
                    <FilterElement size={field.size}>
                      <Box display="flex" justifyContent="center">
                        <Typography variant="subtitle5" color="primary">
                          {field.label}
                        </Typography>
                      </Box>
                      <Slider
                        max={field.max}
                        min={field.min}
                        value={localRangeValues}
                        onChange={(event: Event, newValue: number | number[]) => {
                          setLocalRangeValues(newValue as number[]);
                        }}
                        valueLabelDisplay="auto"
                        step={field.step}
                        valueLabelFormat={(value, i) =>
                          i === 0 ? (
                            <Typography variant="body1">
                              {field.startLabel ? `${field.startLabel}: ${value}` : value}
                            </Typography>
                          ) : (
                            <Typography variant="body1">
                              {field.endLabel ? `${field.endLabel}: ${value}` : value}
                            </Typography>
                          )
                        }
                      />
                    </FilterElement>
                  );
                }

                if (field.type === 'autocomplete') {
                  return (
                    <FilterElement size={field.size}>
                      <Autocomplete
                        multiple
                        options={field.variants}
                        defaultValue={[]}
                        value={localChipsArray[field.name]}
                        onChange={(event, value) => onActivityAdd(event, value, field.name)}
                        onFocus={onFocusInput}
                        renderTags={(
                          value: string[],
                          getTagProps: (activity: { index: number }) => JSX.IntrinsicAttributes,
                        ) =>
                          value.map((option: string, index: number) => {
                            return (
                              <Chip
                                key={option}
                                css={chipCSS}
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={onDeleteChip(field.name, option)}
                              />
                            );
                          })
                        }
                        renderInput={(params: any) => {
                          params.inputProps.onKeyDown = (event, type) =>
                            handleKeyDown(event, type, field);
                          return (
                            <React.Fragment>
                              <TextField
                                {...params}
                                css={activitiesInputCss}
                                label={field.label}
                                onChange={onChangeInputText}
                                fieldProps={{ name: field.name }}
                              />
                              {error && <FormError text="This hobby is invalid" />}
                            </React.Fragment>
                          );
                        }}
                      />
                    </FilterElement>
                  );
                }

                return null;
              })}
              <Box display="flex" justifyContent="flex-end" gap="1rem" style={{ width: '100%' }}>
                <Button color="primary" size="medium" variant="text" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={onReset(resetForm, setFieldValue)}
                >
                  Reset
                </Button>

                <Button color="secondary" size="medium" variant="text" type="submit">
                  apply filter
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Form>
      </Box>
    </React.Fragment>
  );
};

const filterBoxCSS = css`
  padding: 22px;
  display: grid;
  grid-row-gap: 20px;
  width: 500px;
`;

const filterFormCss = css`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FilterElement = styled(Box)<{ size?: 'small' | 'full' }>`
  display: grid;
  width: ${p => (p.size === 'full' ? '100%' : 'calc(50% - 10px)')};
  margin-bottom: 20px;
`;
