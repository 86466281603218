import { useContext } from 'react';

import { SPREADSHEET_CONTEXT } from './SpreadsheetContext';
import { SpreadsheetContextQueryVariables, SpreadsheetContext } from '../types';

/**
 * A custom hook that provides access to the spreadsheet context.
 *
 * @template V - The type of the spreadsheet context query variables. Defaults to `SpreadsheetContextQueryVariables`.
 * @returns The spreadsheet context.
 */
export function useSpreadsheetContext<
  V extends SpreadsheetContextQueryVariables = SpreadsheetContextQueryVariables,
>(): SpreadsheetContext<V> {
  return useContext(SPREADSHEET_CONTEXT) as unknown as SpreadsheetContext<V>;
}
