import { SpreadsheetStats } from 'shared/components/ui';

import { useEventsCount } from '../hooks';

export function EventsStatistics() {
  const { totalEventsCount, pendingEventsCount, upcomingEventsCount, loading } = useEventsCount();

  return (
    <SpreadsheetStats
      loading={loading}
      data={[
        {
          value: totalEventsCount,
          label: 'Total Events',
        },
        {
          value: upcomingEventsCount,
          label: 'Upcoming Events',
        },
        {
          value: pendingEventsCount,
          label: 'Pending Events',
          color: 'secondary',
        },
      ]}
    />
  );
}
