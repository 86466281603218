import { Fragment, useCallback, useMemo } from 'react';
import { MenuItem, Box, css } from '@mui/material';
import { DateTime } from 'luxon';

import { US_TIMEZONES } from '@jebel/constants';
import { fileManyRelations, formatISO8601Date, parseDate } from '@jebel/utils';

import { useEventDelete } from 'admin-features/events/hooks';
import {
  Form,
  FormTextField,
  FormSelect,
  Button,
  ImageInput,
  Icon,
  Typography,
  DateTimePickerField,
  AutocompleteField,
} from 'shared/components/ui';
import { US_STATES_OPTIONS, US_TIMEZONES_OPTIONS } from 'shared/constants';
import {
  EventInfoFragment,
  File,
  EventCreateInput,
  EventUpdateInput,
  AddressCreateInput,
  AddressUpdateInput,
  OrganizationItemFragment,
} from 'shared/graphql';
import { isValidLink, maxLength, required } from 'shared/utils/form';
import { useCurrentUser, useResponsive, useToast } from 'shared/hooks';
import { FormSelectOrganization } from 'shared/components/form';
import { ResultFile } from 'shared/types/files';
import { recordError } from 'shared/utils/record';

import { useEventCreate, useEventEdit } from '../../hooks';
import { FormOtherSponsors } from './FormOtherSponsors';
import { Theme } from '@emotion/react';

const mainContainerCSS = (theme: Theme) => css`
  padding: ${theme.breakpoints.down('sm') ? '0' : '15px 0'};
`;

const formBlockCSS = css`
  display: grid;
  grid-row-gap: 20px;
`;

const timezoneAddressCityBoxCSS = css`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
`;

const zipStateBoxCSS = (isMobile: boolean) => css`
  display: grid;
  grid-gap: 20px;
  ${!isMobile
    ? 'grid-auto-columns: 1fr;  grid-auto-flow: column;'
    : 'grid-auto-rows: 1fr;  grid-auto-flow: row;'}
`;

const eventLinkDescriptionBoxCSS = css`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 20px;
`;

const uploadIconCSS = (theme: Theme) => css`
  font-size: 35px;
  fill: ${theme.palette.primary.light};
`;

const uploadPictureCSS = (theme: Theme) => css`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

const buttonBoxCSS = css`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: 20px;
`;

const cancelButtonCSS = (isMobile: boolean) => (theme: Theme) =>
  css`
    letter-spacing: 1px;
    padding: 9px 15px;
    font-size: ${theme.typography.fontSize - (isMobile ? 2 : 0)}px;
    line-height: 17px;
    color: ${theme.palette.text.secondary};
  `;

const buttonCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize - 2}px;
`;

const deleteButtonCss = (theme: Theme) => css`
  color: ${theme.palette.secondary.light};
`;

const IMAGE_INPUT_BOX_SIZE = 80;

const uploadFileBoxCSS = css`
  :hover {
    background-color: #abbeff;
  }
  cursor: pointer;
  background-color: #f6f8fa;
  border: 1px dashed #bbbbbb;
  height: ${IMAGE_INPUT_BOX_SIZE * 2}px;
`;

export type Values = {
  title: string;
  date: DateTime | string | null | undefined;
  timezone: string | null | undefined;
  link: string | null | undefined;
  description: string | null | undefined;
  location: AddressCreateInput | AddressUpdateInput;
  images: ResultFile[];
  titleSponsor: OrganizationItemFragment | null | undefined;
  otherSponsors: OrganizationItemFragment[];
};

const getOtherSponsorsId = (formData: Values): { id: string }[] => {
  const otherSponsors = formData.otherSponsors || [];

  return otherSponsors.map(({ id }) => ({ id: id! }));
};

interface Props {
  event?: EventInfoFragment;
  wasEventEdited?: boolean;

  onModalClose: () => void;
}

export function EventsCreateModal({ onModalClose, event, wasEventEdited: isEdit = false }: Props) {
  const { isAdmin } = useCurrentUser();
  const { isMobile } = useResponsive();
  const { showError } = useToast();
  const { onEventCreate } = useEventCreate();
  const { onEventEdit } = useEventEdit();
  const { onEventDelete } = useEventDelete();

  const createSubmitTitle = isAdmin ? 'CREATE NEW EVENT' : 'REQUEST NEW EVENT';

  const fallbackTimezone = useMemo(() => {
    const estern = US_TIMEZONES.find(({ abbreviation }) => abbreviation === 'EST');
    return estern ?? US_TIMEZONES[0];
  }, []);

  const timezone = useMemo(() => {
    const timezone = US_TIMEZONES.find(item => item.abbreviation === event?.timezone);
    return timezone?.shortName ?? fallbackTimezone.shortName;
  }, [event?.timezone, fallbackTimezone]);

  const initial: Values = useMemo(() => {
    const title = event?.title ?? '';
    const images = event?.images?.items ?? [];

    const date = parseDate(event?.date);

    const initial: Values = {
      title,
      date: null,
      timezone,
      location: {
        street1: event?.location?.street1,
        zip: event?.location?.zip,
        state: event?.location?.state,
        city: event?.location?.city,
      },
      link: event?.link,
      description: event?.description,
      titleSponsor: event?.titleSponsor,
      otherSponsors: event?.otherSponsors?.items ?? [],
      images,
    };

    if (date.isValid) {
      initial.date = formatISO8601Date(date);
    }

    return initial;
  }, [event, timezone]);

  const onSubmit = async (form: Values) => {
    const timezone = US_TIMEZONES.find(timezone => {
      return timezone.shortName === form.timezone;
    });

    const images: File[] = form?.images ?? [];

    const imagesRelation = fileManyRelations(images, isEdit ? initial.images : undefined);
    const otherSponsors = getOtherSponsorsId(form);

    const payload: EventCreateInput | EventUpdateInput = {
      title: form.title,
      description: form.description,
      link: form.link,
      timezone: timezone?.abbreviation,
      images: imagesRelation,
    };

    if (form.date) {
      payload.date = formatISO8601Date(form.date, { locale: timezone?.abbreviation });
    }

    if (payload.link && !payload.link.match(/^https?:\/\//i)) {
      payload.link = `http://${payload.link}`;
    }

    if (form.location) {
      payload.location = { create: form.location };
    }

    if (form.titleSponsor?.id) {
      payload.titleSponsor = {
        connect: { id: form.titleSponsor.id },
      };
    }

    if (otherSponsors.length > 0) {
      payload.otherSponsors = {
        [isEdit ? 'reconnect' : 'connect']: otherSponsors,
      };
    }

    try {
      if (!isEdit) {
        // Create a new event using the form data

        const response = await onEventCreate(payload as EventCreateInput);

        if (!response?.id) {
          throw new Error(`Response must contain a the data of the event. Got ${typeof response}`);
        }

        onModalClose();
        return;
      }

      if (!event?.id) {
        throw new Error('Event must have an ID to be edited');
      }

      const response = await onEventEdit(payload, event.id);

      if (!response?.data?.eventUpdate) {
        throw new Error(
          `Response must contain the data of the event. Got ${typeof response?.data?.eventUpdate}`,
        );
      }

      onModalClose();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onDeleteEvent = useCallback(async () => {
    await onEventDelete({ id: event?.id ?? '' });
    onModalClose();
  }, [event?.id, onEventDelete, onModalClose]);

  return (
    <Box css={mainContainerCSS}>
      <Form
        oldCss={formBlockCSS}
        onSubmit={onSubmit}
        validateOnBlur={false}
        initialValues={initial}
      >
        {form => (
          <Fragment>
            <FormTextField
              inputProps={{
                label: 'EVENT TITLE',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'title', validate: required }}
            />

            <DateTimePickerField
              name="date"
              allowKeyboardControl={true}
              label="EVENT DATE"
              timeLabel="EVENT TIME"
              inputVariant="outlined"
              validate={required}
              disablePast
            />

            <Box css={timezoneAddressCityBoxCSS}>
              <FormSelect
                selectProps={{
                  children: US_TIMEZONES_OPTIONS.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'TIMEZONE',
                }}
                fieldProps={{ name: 'timezone', validate: required }}
              />
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'EVENT ADDRESS',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'location.street1' }}
              />
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'CITY',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'location.city', validate: required }}
              />
            </Box>
            <Box css={zipStateBoxCSS(isMobile)}>
              <FormTextField
                inputProps={{
                  label: 'ZIP Code',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'location.zip', validate: required }}
              />

              <AutocompleteField
                label="State"
                name="location.state"
                options={US_STATES_OPTIONS}
                required
              />
            </Box>
            <Box css={eventLinkDescriptionBoxCSS}>
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'EVENT LINK',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'link', validate: isValidLink }}
              />
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'DESCRIPTION',
                  multiline: true,
                  rows: '7',
                  rowsMax: '7',
                  variant: 'outlined',
                }}
                fieldProps={{
                  name: 'description',
                  validate: maxLength,
                }}
              />
            </Box>

            {isAdmin && (
              <Fragment>
                <Box mt={2}>
                  <Typography variant="subtitle4" color="primary">
                    Title Sponsor
                  </Typography>
                  <Typography variant="body1">
                    Type the name of the sponsor and select it from the list. Their logo will be
                    displayed at the top of the event detail screen with a link to their profile.
                  </Typography>
                </Box>

                <FormSelectOrganization
                  name="titleSponsor"
                  label="Title Sponsor"
                  variant="outlined"
                  onlyManaged={!isAdmin}
                  onlyActive
                />

                <Box mt={2}>
                  <Typography variant="subtitle4" color="primary">
                    Other Sponsors
                  </Typography>

                  <Typography variant="body1">
                    Type the name of the sponsor(s) and select it from the list. Their logo will be
                    displayed on the event detail screen with a link to their profile.
                  </Typography>
                </Box>

                <FormOtherSponsors />
              </Fragment>
            )}

            <ImageInput
              name="images"
              height={IMAGE_INPUT_BOX_SIZE}
              width={IMAGE_INPUT_BOX_SIZE}
              initialValue={event?.images?.items}
              maxFiles={5}
              showDescription={true}
              onChange={image => {
                form.setFieldValue(`images`, image);
              }}
            >
              <Box
                css={uploadFileBoxCSS}
                display="grid"
                gridTemplateRows="min-content min-content"
                alignItems="center"
                justifyItems="center"
                alignContent="center"
              >
                <Icon css={uploadIconCSS} name="CloudUpload" variant="filled" />
                <Typography css={uploadPictureCSS}>Upload pictures or videos here</Typography>
              </Box>
            </ImageInput>

            <Box css={buttonBoxCSS}>
              <Button
                css={cancelButtonCSS(isMobile)}
                onClick={onModalClose}
                color="primary"
                disabled={form.isSubmitting}
                loading={false}
                size="medium"
                variant="text"
              >
                Cancel
              </Button>

              {isAdmin && isEdit && (
                <Button
                  css={deleteButtonCss}
                  onClick={onDeleteEvent}
                  color="primary"
                  disabled={form.isSubmitting}
                  variant="text"
                >
                  DELETE EVENT
                </Button>
              )}

              <Button
                css={isMobile && buttonCss}
                loading={form.isSubmitting}
                color="primary"
                disableElevation
                disabled={form.isSubmitting}
                variant="contained"
                type="submit"
              >
                {isEdit ? `SAVE CHANGES` : createSubmitTitle}
              </Button>
            </Box>
          </Fragment>
        )}
      </Form>
    </Box>
  );
}
