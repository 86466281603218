import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';

import { LegacyContent } from '../components';

export function LegacyPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <SearchProvider>
          <LegacyContent />
        </SearchProvider>
      }
    />
  );
}
