import { Typography, TypographyProps } from '@material-ui/core';
import styled from '@emotion/styled';

import { LoadingOverlay } from '../LoadingOverlay';
import { CardLayout } from 'shared/components/layouts';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Stat = styled(CardLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const StatValue = styled(Typography)`
  font-size: 3rem;
  line-height: 2.5rem;
  text-align: center;
`;

const StatLabel = styled(Typography)``;

export interface SpreadsheetStat {
  /**
   * The value to display.
   */
  value: string | number;

  /**
   * The label of the value.
   */
  label: string;

  /**
   * The color of the value.
   * @default 'textPrimary'
   */
  color?: TypographyProps['color'];

  /**
   * A function to transform the value before displaying it.
   * @param value The value to transform.
   */
  customTransform?(value: string | number): string;
}

export type SpreadsheetStatsProps = {
  data: SpreadsheetStat[];

  /**
   * Whether the component is in a loading state.
   * @default false
   */
  loading?: boolean;

  /**
   * A function to transform the value before displaying it.
   * @param value The value to transform.
   */
  transform?(value: string | number): string;
};

export function SpreadsheetStats({ data, loading, transform }: SpreadsheetStatsProps) {
  return (
    <Container>
      {data.map(({ label, value, color, customTransform = defaultFormat }) => {
        const displayValue = customTransform?.(value) ?? transform?.(value) ?? value;

        return (
          <Stat>
            <LoadingOverlay loading={loading}>
              <StatValue variant="subtitle2" color={color}>
                {displayValue}
              </StatValue>
            </LoadingOverlay>

            <LoadingOverlay loading={loading}>
              <StatLabel color="textSecondary">{label}</StatLabel>
            </LoadingOverlay>
          </Stat>
        );
      })}
    </Container>
  );
}

const defaultFormat = (num?: string | number | null | undefined) => {
  if (!num || typeof num === 'string') {
    return num ?? 'N/A';
  }

  const thousands = num / 1000;

  if (thousands > 3) {
    return `${thousands.toFixed(1)}K`;
  }

  if (thousands > 1) {
    return `${Math.trunc(thousands)},${num % 1000}`;
  }

  return num;
};
