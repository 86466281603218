import { useEventsCountQuery } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

/**
 * Custom hook to fetch and return the count of events for a specific school.
 *
 * This hook uses the `useSchoolConfiguration` hook to get the current school configuration
 * and the `useEventsCountQuery` hook to fetch the event counts based on the school ID.
 */
export function useEventsCount() {
  const { data: school } = useSchoolConfiguration();

  const { data, loading } = useEventsCountQuery({
    skip: !school,
    variables: {
      filter: {
        school: {
          // Filter events by school.
          // https://github.com/jebelapp/jebel/issues/1678
          id: { equals: school?.id },
        },
      },
    },
  });

  const totalEventsCount = data?.total?.count ?? 0;
  const pendingEventsCount = data?.pending?.count ?? 0;
  const upcomingEventsCount = data?.upcoming?.count ?? 0;

  return {
    totalEventsCount,
    pendingEventsCount,
    upcomingEventsCount,
    loading,
  };
}
